*p {
  margin: 0;
  padding: 0;
}

.button_group_container {
  .ant-radio-button-checked {
    background-color: #363b95 !important;
    border: none !important;
  }

  .ant-radio-button {
    border-radius: 10px;
    border: none;
  }

  .ant-radio-button-wrapper::before {
    display: none !important;
  }

  .ant-radio-button-wrapper {
    height: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: transparent !important;
    // border-color: transparent !important;
    border: none;
    color: rgba(54, 59, 149, 1);
    padding: 0 10px;
  }

  .ant-radio-group {
    display: flex;
    margin-right: 15px;
    gap: 20px;
  }

  .ant-input-affix-wrapper {
    background: #d9d9d9;
  }
}

.login_form {
  .ant-input-affix-wrapper {
    border-bottom: 1px solid rgb(211, 211, 211) !important;
  }

  a {
    color: rgb(64 61 61 / var(--tw-text-opacity)) !important;
    padding: 10px;
  }
}

.ant-dropdown-menu-item:hover {
  background: rgb(224, 220, 220) !important;
}
