* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  font-size: 14px;
  margin: 0;
}
.scroll::-webkit-scrollbar {
  width: 7px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #238793;
  border-radius: 9999px;
}

.scroll {
  scrollbar-width: thin;
  scrollbar-color: #238793;
}
.ant-table-pagination.ant-pagination {
  margin: 30px !important;
}

.ant-table-cell {
  color: #363b95 !important;
  text-align: center !important;
}

.ant-table-row {
  cursor: pointer !important;
}

th.ant-table-cell {
  background-color: #f4f7f9 !important;
  color: #363b95 !important;
  font-weight: 700 !important;
  text-align: center !important;
  text-transform: uppercase !important;
}

th.ant-table-cell::before {
  background-color: #3b8098 !important;
}

tbody {
  margin-top: 25px !important;
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #006d7d;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

.ant-picker {
  width: 135px !important;
  border-radius: 6px !important;
}

.swiper-button-next {
  display: none !important;
}

.swiper-button-prev {
  display: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -5px !important;
}

.ant-image-preview-img {
  margin: 10% auto !important;
  vertical-align: center !important;
  max-width: 400px !important;
}

.ant-image-preview-img-wrapper {
  position: relative !important;
}

.ant-image-preview-img {
  bottom: 30px !important;
}

.active {
  background-color: #363b95;
  border-radius: 20px;
  color: #fff;
  opacity: 1;
}
.active:hover {
  color: #fff;
}

.apexcharts-legend-marker {
  background: #49706f !important;
}

@media screen and (max-width: 600px) {
  .apexcharts-legend {
    display: none !important;
  }
  .ant-image-preview-img {
    max-width: 250px !important;
    margin: 18% auto !important;
  }
}

@media screen and (max-width: 900px) {
  th.ant-table-cell {
    text-align: start !important;
    text-transform: none !important;
  }
  .ant-table-cell {
    text-align: start !important;
  }
}

.ant-btn-primary {
  background-color: #363b95 !important;
}
.ant-btn-primary:disabled {
  background-color: #aaa !important;
  color: #fff !important;
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
  font-size: 16px;
  font-weight: 500;
  color: #116072 !important;
}
.ant-modal-confirm-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #116072 !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}
.ant-input {
  border-radius: 2px !important;
}

.ant-select-selector {
  border-radius: 2px !important;
}

.icon-btn {
  width: 50px;
  height: 50px;
  border: 1px solid #363b95;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  transition: width 0.2s ease-in-out;
  font-weight: 700;
  color: #363b95;
  font-family: inherit;
}

.add-btn:hover {
  width: 220px;
}

.add-btn::before,
.add-btn::after {
  transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  content: "";
  position: absolute;
  height: 4px;
  width: 10px;
  top: calc(50% - 2px);
  background: #363b95;
}

.add-btn::after {
  right: 14px;
  overflow: hidden;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.add-btn::before {
  left: 14px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.icon-btn:focus {
  outline: none;
}

.btn-txt {
  opacity: 0;
  transition: opacity 0.2s;
}

.add-btn:hover::before,
.add-btn:hover::after {
  width: 4px;
  border-radius: 2px;
}

.add-btn:hover .btn-txt {
  opacity: 1;
}

.add-icon::after,
.add-icon::before {
  transition: all 0.2s ease-in-out;
  content: "";
  position: absolute;
  height: 20px;
  width: 2px;
  top: calc(50% - 10px);
  background: #363b95;
  overflow: hidden;
}

.add-icon::before {
  left: 22px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.add-icon::after {
  right: 22px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.add-btn:hover .add-icon::before {
  left: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.add-btn:hover .add-icon::after {
  right: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.my_add_button {
  --color: #363b95;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin: 20px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 25px;
  font-weight: 500;
  color: var(--color);
}

.my_add_button:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.my_add_button:hover {
  color: #fff;
  border: none;
}

.my_add_button:hover:before {
  top: -30px;
  left: -30px;
}

.my_add_button:active:before {
  background: #185468;
  transition: background 0s;
}

.my_add_button2 {
  --color: #363b95;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  margin: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
}

.my_add_button2:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.my_add_button2:hover {
  color: #fff;
  border: none;
}

.my_add_button2:hover:before {
  top: -30px;
  left: -30px;
}

.my_add_button2:active:before {
  background: #185468;
  transition: background 0s;
}

/* wave placeholder classes */
/* .wave-group {
  position: relative;
}

.wave-group .input {
  font-size: 15px;
  padding: 14px 14px 7px 4px;
  display: block;
  width: 240px;
  border: none;
  color: #5e5757;
  background: transparent;
}

.wave-group .input:focus {
  outline: none;
}

.wave-group .label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  display: flex;
}

.wave-group .label-char {
  transition: 0.2s ease all;
  transition-delay: calc(var(--index) * 0.05s);
}

.wave-group .input:focus ~ label .label-char,
.wave-group .input:valid ~ label .label-char {
  transform: translateY(-23px);
  font-size: 14px;
  color: #3b8098;
}

.wave-group .bar {
  position: relative;
  display: block;
  width: 240px;
}

.wave-group .bar:before,
.wave-group .bar:after {
  content: "";
  width: 0;
  position: absolute;
  background: #3b8098;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.wave-group .bar:before {
  left: 50%;
}

.wave-group .bar:after {
  right: 50%;
}

.wave-group .input:focus ~ .bar:before,
.wave-group .input:focus ~ .bar:after {
  width: 50%;
} */

.spinner {
  --clr: #363b95;
  --gap: 8px;
  /* gap between each circle */
  width: 100px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.spinner span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
}

.spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
}

.spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
}

.spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}
.ant-input-password {
  border: none !important;
  width: 100% !important;
}

.ant-input-affix-wrapper-focused {
  border: none !important;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #363b95;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.ant-picker {
  background-color: #fff !important;
}

.ant-image-mask-info {
  display: none !important;
}

.ant-tooltip-inner {
  display: none !important;
}

.ant-upload-list-picture-card-container {
  width: 100% !important;
  height: 140px !important;
  margin: 0 !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border: none !important;
}

.ant-form-item-row {
  display: block !important;
}

.ant-form-item-label > label {
  height: 26px !important;
  color: #363b95 !important;
}

.ant-form-item {
  margin: 0 !important;
}
